export default {
  namespaced: true,

  state: {
    brand: null,
    brandNiceName: null,
    hideNavBar: false,

    // Should contain all the names of defalult minim icons that can be overridden by the current brand
    // example: the EZECOM brand has it's own version of the "nav-network" icon so when in the ezecom
    // brand "nav-network" should be in this array
    brandOverrideableIcons: []
  },

  actions: {
    fetchBrand(context) {
      let brand;
      const app = document.querySelector('#app');

      if (app) {
        brand = app.dataset.ispBrand;
      }

      context.commit('set_brand', brand || 'minim');
      return brand;
    },
  },

  mutations: {
    set_brand(state, brand) {
      state.brand = brand;
      switch (brand) {
      case 'motosync':
        state.brandNiceName = '<strong>moto</strong>sync';
        break;
      case 'intellifi':
        state.brandNiceName = 'Intellifi';
        break;
      case 'inq':
        state.brandNiceName = 'Inq. Analytics';
        break;
      case 'mca':
        state.brandNiceName = 'DStv Trusted Home';
        break;
      case 'trusted-home':
        state.brandNiceName = 'Trusted Home';
        break;
      case 'vgi':
        state.brandNiceName = 'Uublu';
        break;
      case 'motorola':
        state.brandNiceName = 'Motorola';
        state.hideNavBar = true;
        break;
      case 'ezecom':
        state.brandNiceName = 'EzeWiFi';
        state.brandOverrideableIcons = [
          'nav-network',
          'nav-profile',
          'nav-timeline',
          'nav-help',
          'nav-settings',
          'profile',
          'profile-add',
          'app-info'
        ];
        break;
      default:
        state.brandNiceName = 'Minim';
        break;
      }

      return state;
    }
  },

  getters: {
    getBrandOverrideIconName: state => iconName => {
      // Check to see if the icon has been declared as overrideable by the brand
      const indexOfBrandIcon = state.brandOverrideableIcons.indexOf(iconName);

      if (indexOfBrandIcon !== -1) return iconName + '-' + state.brand; // If so it returns the brand override version of the icon
      else return iconName; // else return the default icon
    },

    getBrand(state) {
      return state.brand;
    },

    brandSimpleName: state => {
      if (state.brand === 'motosync') return 'motosync';
      else return state.brandNiceName;
    },

    privacyPolicyLink() {
      return 'https://motorolanetwork.com/policies/privacy-policy';
    },

    termsAndConditionsLink() {
      return 'https://motorolanetwork.com/policies/terms-of-service';
    }
  }
};
